
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  html {
    position: relative;
    min-height: 100%;
  }
  
  
  p {
    font-size: 1.1rem;
  }
  
  a {
    text-decoration: none;  
  }
  
  a:link {
    color: rgb(0, 0, 0);
    background-color: transparent;
    text-decoration: none;
  }
  
  a:visited {
    color: rgb(39, 39, 39);
    background-color: transparent;
    text-decoration: none;
  }
  
  a:hover {
    color: rgb(20, 102, 255);
    background-color: transparent;
    text-decoration: underline;
  }
  
  a:active {
    color: rgb(7, 99, 175);
    background-color: transparent;
    text-decoration: underline;
  }
  
  
  .main_banner {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  
  .footer {
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
  }
  
  #site {
    margin: 0 auto;
  }
  
  #canvas {
    padding: 0;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  #navButton {
    padding: 0;
    padding-bottom: 15px;
    border: none;
    background: none;
    outline: none;
    margin-right: 0px !important;
  }
  
  .tab 
    {
      margin-left: 40px;
    }

#icon_space {
  margin-left: 0.5rem;
  margin-right: 0.5rem;

}